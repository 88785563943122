/** @jsx jsx */
// eslint-disable-next-line no-unused-vars
import React from 'react'
import { Flex, Box, jsx } from 'theme-ui'
import { getSrc } from 'gatsby-plugin-image'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import { useTranslation } from 'react-i18next'

import BackButton from '../../components/BackButton'
import Button from '../../components/Button'
import Layout from './layout'
import SEO from './seo'
import PostTitle from './post-title'
import PostDate from './post-date'
import PostFooter from './post-footer'
import PostHero from './post-hero'
import C from '../../constants'

const Post = ({
  data: {
    post,
    site: {
      siteMetadata: { title },
    },
  },
  location,
  previous,
  next,
}) => {
  const { t } = useTranslation()

  const handleOnSignUpClick = () => {
    window.open(C.links.signupLink)
  }

  return (
    <Layout location={location} title={title}>
      <SEO
        title={post.title}
        description={post.excerpt}
        imageSource={
          post.socialImage ? getSrc(post.socialImage) : getSrc(post.image)
        }
        imageAlt={post.imageAlt}
      />

      <Box
        sx={{
          maxWidth: '1280px',
          paddingX: [2, 2, 4, 4],
        }}
      >
        <Box marginY={4}>
          <BackButton />
        </Box>
        <main>
          <article>
            <header>
              <Box mb={[2, 2, 4, 5]}>
                <PostTitle>{post.title}</PostTitle>
              </Box>
              <Box mb={[4, 3, 2, 1]}>
                <PostDate>{post.date}</PostDate>
              </Box>
              <Flex
                p={[0, 2, 4, 5]}
                mb={4}
                sx={{ justifyContent: 'center', alignItems: 'center' }}
              >
                <PostHero post={post} />
              </Flex>
            </header>
            <section>
              <MDXRenderer>{post.body}</MDXRenderer>
            </section>
          </article>
        </main>
        <Flex sx={{ justifyContent: 'center' }}>
          <Button
            sx={{ margin: 2 }}
            variant="primary"
            onClick={handleOnSignUpClick}
          >
            {t('Global:getStarted')}
          </Button>
        </Flex>
        <PostFooter {...{ previous, next }} />
      </Box>
    </Layout>
  )
}

export default Post
