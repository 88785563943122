/** @jsx jsx */
// eslint-disable-next-line no-unused-vars
import * as React from 'react'
import { Flex, Text, jsx } from 'theme-ui'
import { useTranslation } from 'react-i18next'

import { LinkWrapper } from '../../components/Link'
import ArrowLeft from '../../assets/svgs/arrow_left.svg'
import ArrowRight from '../../assets/svgs/arrow_right.svg'

const PostFooter = ({ previous, next }) => {
  const { t } = useTranslation()
  return (
    <Flex
      p={[2, 2, 4, 4]}
      sx={{ justifyContent: 'space-between', alignItems: 'center' }}
    >
      {previous ? (
        <LinkWrapper href={previous?.slug}>
          <Flex sx={{ alignItems: 'center' }}>
            <ArrowLeft
              sx={{
                width: 40,
                height: 60,
                path: { fill: 'summerSky' },
              }}
            />
            <Text sx={{ color: 'summerSky' }}>{t('Global:previous')}</Text>
          </Flex>
        </LinkWrapper>
      ) : (
        <Flex />
      )}
      {next ? (
        <LinkWrapper href={next?.slug}>
          <Flex sx={{ alignItems: 'center' }}>
            <Text sx={{ color: 'summerSky' }}>{t('Global:next')}</Text>
            <ArrowRight
              sx={{
                width: 40,
                height: 60,
                path: { fill: 'summerSky' },
              }}
            />
          </Flex>
        </LinkWrapper>
      ) : (
        <Flex />
      )}
    </Flex>
  )
}

export default PostFooter
